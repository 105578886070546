<template>
  <component :is="'PageWithColoredCenteredBox'">
    <div class="page-centered-box">
      <h2 class="h-2 margin-top-l">{{ $t('page-keep-them.title') }}</h2>
      <p class="margin-top-s text-center" v-html="$t('page-keep-them.text')"></p>
      <img class="margin-top-s img-responsive" src="@/assets/img/glasses.svg"/>
      <button class="btn primary-bg body-bold small-btn margin-top-l"
              v-on:click="this.$changePageWithAnalyticsStep(this.$store, 'PageInFullBrightness')">
        {{ $t('page-keep-them.button-text') }}
      </button>
    </div>
  </component>
</template>

<script>
import PageWithColoredCenteredBox from "@/components/pages/generic/PageWithColoredCenteredBox";


export default {
  components: {PageWithColoredCenteredBox},
};
</script>
